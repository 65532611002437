import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  padding-top: 66.66%;
  position: relative;
`;

const MapContainer = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  right: 0;
`;

interface GoogleMapsProps {
  url?: string | null;
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ url }) => {
  if (!url) {
    return <></>;
  }
  return (
    <Root>
      <MapContainer src={url}></MapContainer>
    </Root>
  );
};

export default GoogleMaps;
