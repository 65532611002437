import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import YoutubeVideo from './youtube-video';
import GoogleMaps from './google-maps';
import { BlogGallery } from './blog-gallery';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

const shortCodes = { YoutubeVideo, GoogleMaps, BlogGallery };

const Content = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
    scroll-margin-top: 6rem;
  }
`;

interface BlogPostContentProps {
  content: string;
}

const BlogPostContent: React.FC<BlogPostContentProps> = ({ content }) => (
  <Content>
    <MDXProvider components={shortCodes}>
      <MDXRenderer>{content}</MDXRenderer>
    </MDXProvider>
  </Content>
);

export default BlogPostContent;
