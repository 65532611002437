import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/site-layout/site-layout';
import BlogPost from '../components/blog-post/blog-post';
import { BlogPostContext } from '../types/BlogPostContext';
import { Head } from '../components/site-layout/head';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';

const BlogPostBody = styled.div`
  flex-grow: 1;
  justify-content: center !important;
  display: flex !important;
`;

const BlogPostTemplate: React.FC<PageProps<GatsbyTypes.BlogPostBySlugQuery, BlogPostContext>> = ({
  data,
  pageContext,
  location,
}) => {
  const post = data.mdx;
  return (
    <Layout location={location}>
      <Head
        title={post?.frontmatter?.title || 'Blog Post'}
        description={post?.excerpt}
        imageSrc={
          post?.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData &&
          getImage(post?.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData)?.images.fallback?.src
        }
      />
      <BlogPostBody>
        <BlogPost data={data} pageContext={pageContext} />
      </BlogPostBody>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $galleryImagesPath: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 2000)
          }
        }
      }
    }
    thumbnails: allFile(filter: { relativePath: { regex: $galleryImagesPath } }) {
      ...GalleryThumbnailFragment
    }
    images: allFile(filter: { relativePath: { regex: $galleryImagesPath } }) {
      ...GalleryImageFragment
    }
  }
`;
