import React from 'react';
import PrevNextButtons from '../common/prev-next-buttons';
import { BlogPostContext } from '../../types/BlogPostContext';

interface PrevNextBlogPostLinksProps {
  pageContext: BlogPostContext;
}

const PrevNextBlogPostLinks: React.FC<PrevNextBlogPostLinksProps> = ({ pageContext: { previous, next } }) => (
  <PrevNextButtons
    nextLabel={next && next.frontmatter.title}
    nextTo={next && next.fields.slug}
    prevLabel={previous && previous.frontmatter.title}
    prevTo={previous && previous.fields.slug}
  />
);

export default PrevNextBlogPostLinks;
