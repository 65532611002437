import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  padding-top: 66.66%;
  position: relative;
`;

const VideoContainer = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  right: 0;
`;

interface YoutubeVideo {
  videoId?: string | null;
}

const YoutubeVideo: React.FC<YoutubeVideo> = ({ videoId }) => {
  if (!videoId) {
    return <></>;
  }
  return (
    <Root>
      <VideoContainer
        src={`https://www.youtube-nocookie.com/embed/${videoId}?wmode=transparent&vq=hd1080`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Root>
  );
};

export default YoutubeVideo;
