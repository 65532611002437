import React from 'react';
import FeaturedImage from './featured-image';
import PrevNextBlogPostLinks from './prev-next-blog-post-links';
import { BlogPostContext } from '../../types/BlogPostContext';
import BlogPostContent from './blog-post-content';
import { BlogGalleryContext } from './blog-gallery';
import styled from 'styled-components';
import BlogPostMeta from './blog-post-meta';

const Root = styled.div`
  text-align: left;
  margin-top: 1rem;
  max-width: 1280px;
  flex-grow: 1;
  margin-left: 2rem;
  margin-right: 2rem;
`;

interface BlogPostProps {
  data: GatsbyTypes.BlogPostBySlugQuery;
  pageContext: BlogPostContext;
}

const BlogPost: React.FC<BlogPostProps> = ({ data, pageContext }) => {
  const post = data.mdx;
  if (!post?.frontmatter) {
    return <></>;
  }
  const { title, featuredImage, tags } = post.frontmatter;
  return (
    <Root>
      <h1>{title}</h1>
      <BlogPostMeta date={post.frontmatter?.date} tags={tags} />
      <FeaturedImage image={featuredImage?.childImageSharp?.gatsbyImageData} />
      <BlogGalleryContext.Provider value={{ images: data.images, thumbnails: data.thumbnails }}>
        {post.body && <BlogPostContent content={post.body} />}
      </BlogGalleryContext.Provider>
      <PrevNextBlogPostLinks pageContext={pageContext} />
    </Root>
  );
};

export default BlogPost;
