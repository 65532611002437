import React, { createContext, useContext } from 'react';
import Gallery from '../image-gallery/gallery';
interface GalleryImages {
  thumbnails?: GatsbyTypes.GalleryThumbnailFragmentFragment;
  images?: GatsbyTypes.GalleryImageFragmentFragment;
}

export const BlogGalleryContext = createContext<GalleryImages>({});

export const BlogGallery: React.FC<{}> = ({}) => {
  const galleryImages = useContext(BlogGalleryContext);
  if (!galleryImages.images || !galleryImages.thumbnails) return <></>;
  return <Gallery thumbnails={galleryImages.thumbnails} images={galleryImages.images} />;
};
